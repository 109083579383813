<template>
  <div id='shop'>
<!--    <img src='../assets/shop/shop-banner.png' alt='' class='banner-img'>-->
    <div class='banner_1'>
      <div class='swiper_content'>
        <!--        <div class="swiper-container">-->
<!--        <swiper :options='swiperOptions1'>-->
<!--          <swiper-slide v-for='(item,index) in logoList' :key='index'>-->
            <div v-if='logoList.length > 0'>
              <a :href='logoList[0].link_url&&logoList[0].link_url.indexOf("http") >= 0  ? logoList[0].link_url : null' target='_blank'><img :src='logoList[0].img'/></a>
            </div>
<!--          </swiper-slide>-->
          <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
          <!--                      <div class="swiper-pagination" slot="pagination"></div>-->
          <!-- 如果需要导航按钮 -->
          <!--          <div class='swiper-button-prev prev1' slot='button-prev'></div>-->
          <!--          <div class='swiper-button-next next1' slot='button-next'></div>-->
<!--        </swiper>-->
        <!--      </div>-->
      </div>
    </div>
    <!--    宅机弟自孵化账号-->
    <div class='head-info-item'>
<!--      <div class='rectangle-position'>-->
<!--        <img src='../assets/rectangle.png' alt=''>-->
<!--      </div>-->
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{$t('LIVE.Zhaijid')}}</span>
<!--          <img src='../assets/shop/self-incubation.png' alt='' class="images">-->
        </div>
        <div class='shop-content-info'>
          <div class="swiper swiper1">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for='(item, index) in self_incubation' :key='index'>
                <a :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank' style='display: inline-block;width: 100%;'>
                  <img :src='item.img' alt='' class="anchor-avatar">
                </a>
<!--                <div class='know_us' @click="gotoMore">-->
<!--                  <el-button class='know_more'>了解华星>>></el-button>-->
<!--                </div>-->
              </div>

            </div>
            <div class="swiper-button-prev prev-left1"></div>
            <div class="swiper-button-next next-right1"></div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination incubation-s"></div>

          </div>
        </div>
      </div>
<!--      <div class='rectangle-right'>-->
<!--        <img src='../assets/rectangle.png' alt=''>-->
<!--      </div>-->
    </div>
    <!--    华星严选-->
    <div class='head-info-item'>
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{$t('LIVE.strictSelection')}}</span>
<!--          <img src='../assets/shop/yan-xuan.png' alt='' class='images popular-img'>-->
        </div>
        <div class='hx-yanxuan'>
          <div class="swiper swiper2">
            <div class="swiper-wrapper">
              <div class="swiper-slide yanxuan-list" v-for='(item, index) in yanXuan_list' :key='index'>
                <div class='lists-con'>
                  <a style='height: 446px;width: 100%' :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank'><img :src='item.img' alt='' class="yanxuan-avatar"></a>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination yanxuan_pagination"></div>
            <div class="swiper-button-prev swiper-button-top"></div>
            <div class="swiper-button-next swiper-button-bottom"></div>
          </div>
        </div>
      </div>
<!--      <div class='rectangle-right rights'>-->
<!--        <img src='../assets/rectangle.png' alt=''>-->
<!--      </div>-->
    </div>
    <!--    爆款案例-->
    <div class='head-info-item'>
<!--      <div class='left-s'>-->
<!--        <img src='../assets/left-img.png' alt=''>-->
<!--      </div>-->
<!--      <div class='rectangle-position lefts'>-->
<!--        <img src='../assets/rectangle.png' alt=''>-->
<!--      </div>-->
      <div class='header-anchor' style='width :62%'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{$t('LIVE.PopularCases')}}</span>
<!--          <img src='../assets/shop/explosion.png' alt='' class='images explosion-img'>-->
        </div>
        <div style='position:relative;'>
          <div class='hot-case'>
            <div class="swiper swiper3">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for='(item, index) in explosion_list' :key='index'>
                  <a style='width: 100%' :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank'><img :src='item.img' alt='' class="avatar-explosion"></a>
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev swiper-button-left"></div>
              <div class="swiper-button-next swiper-button-right"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    近期资讯-->
    <div class='head-info-item' style='background: #F6F6F6;'>
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{$t('LIVE.RecentInformation')}}</span>
<!--          <img src='../assets/shop/information.png' alt='' class='images information-img'>-->
        </div>
        <div class='hot-information recent-information'>
          <div class="swiper swiper4">
            <div class="swiper-wrapper">
              <div class="swiper-slide information-s" v-for='(item, index) in information_list' :key='index'>
                <a :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank' style='width: 100%'>
                  <img :src='item.img' alt='' class="avatar2">
<!--                  <div class='isBackGround'></div>-->
                </a>
              </div>
            </div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev swiper-button-left"></div>
            <div class="swiper-button-next swiper-button-right"></div>
          </div>
        </div>
      </div>
    </div>
<!--    :style='{"background-image": "url("+shop_join_img+")","background-size":"100% 100%", "background-repeat":"no-repeat"}'-->
    <div class='recruit-item'>
      <swiper :options='swiperOptions2'>
        <swiper-slide v-for='(item,index) in shop_join_img' :key='index'>
          <img :src='item' alt=''>
        </swiper-slide>
      </swiper>
      <div class="content-s">
        <div class='form-item-s'>
          <div class="name-d">{{$t("LIVE.businessCooperation")}}</div>
          <el-form ref="form" :model="form" :rules='rules' label-position="left">
            <div class="back-s">
              <el-form-item label="" prop='brand'>
                <el-input v-model="form.brand" size='mini' :placeholder='$t("LIVE.brandText")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='live_id'>
                <el-input v-model="form.live_id" size='mini' :placeholder='$t("LIVE.intention")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='desc'>
                <el-input v-model="form.desc" size='mini' :placeholder='$t("LIVE.cooperationNeeds")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='is_brand'>
                <el-input v-model="form.is_brand" size='mini' :placeholder='$t("LIVE.brandDirectSale")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='wechat'>
                <el-input v-model="form.wechat" size='mini' :placeholder='$t("LIVE.wechat")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='tel'>
                <el-input v-model="form.tel" size='mini' :placeholder='$t("LIVE.phone")'></el-input>
              </el-form-item>
            </div>
            <el-form-item label-width='0' style='text-align: center'>
              <el-button type="primary" size='small' @click="onSubmit('form')">{{$t("LIVE.submitText")}}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class='data-see'>
          <img src='../assets/shop/huaxing-yx2.png' alt='' class="image-platform">
        </div>
      </div>
    </div>
    <div class='brand_wrapper'>
        <div class='brand_title'>{{$t('LIVE.cooperativeBrand')}}</div>
        <div class='brand_content'>
          <vue-seamless-scroll :data="brand_list" :class-option="defaultOption"  class="seamless-warp2">
            <ul class="item">
              <li v-for="(item ,index) in brand_list" v-if='index % 2 == 1' style='margin-top: 30px' :key='index'>
                <img :src='item.img' />
              </li>
            </ul>
          </vue-seamless-scroll>
          <vue-seamless-scroll :data="brand_list" :class-option="defaultOption"  class="seamless-warp2">
            <ul class="item">
              <li v-for="(item , index) in brand_list" v-if='index % 2 == 0' style='margin-top: 10px'>
                <img :src='item.img' />
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
    </div>
<!--    合作品牌-->
<!--    <div class='head-info-item'>-->
<!--      <div class='header-anchor'>-->
<!--        <div style='margin: 30px 0 30px 0;text-align: center;'>-->
<!--          <span>合作品牌</span>-->
<!--          <img src='../assets/shop/cooperative.png' alt='' class='images information-img'>-->
<!--        </div>-->
<!--        <div class='cooperation-brand'>-->
<!--          <img :src='item.img' alt='' v-for='(item, index) in brand_list' :key='index'>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
  // import homeBanner from '../components/homeBanner'
  import vueSeamlessScroll from 'vue-seamless-scroll'
import Swiper from 'swiper'
  import { Logo, index_List } from '@/api/banner'
import { getShopBanner, shopJoin, getBrand } from '@/api/shop'

export default {
  name: 'shop',
  components:{
    vueSeamlessScroll
    // homeBanner
  },

  data(){
    return{
      newsList: [
        {
        'img': 'https://img0.baidu.com/it/u=617215503,188424665&fm=253&fmt=auto&app=138&f=JPG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=878588444,1785929995&fm=253&fmt=auto&app=138&f=JPG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=4276295978,1564416943&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img0.baidu.com/it/u=503580633,3567414311&fm=253&fmt=auto&app=120&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=417744379,2733026919&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=3793272314,3579832883&fm=253&fmt=auto&app=138&f=PNG?w=120&h=120'
        },
        {
          'img': 'https://img0.baidu.com/it/u=365019288,3680240542&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=2878383006,2396702772&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=4110101154,77755075&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img0.baidu.com/it/u=2413108766,1138688916&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=2246582671,1076022454&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=2439673203,2602330429&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=4087371483,2459403015&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img0.baidu.com/it/u=4004226857,4015547609&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img0.baidu.com/it/u=728270901,3143689237&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=166996070,3813994298&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=1229670147,54142161&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=3329779680,1730727527&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=95201979,4265305100&fm=253&fmt=auto&app=138&f=PNG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=2667643370,4182542990&fm=253&fmt=auto&app=138&f=GIF?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=721165360,1838369749&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=3024818213,924427534&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=2279560675,1435387031&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=1775013149,858928440&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img0.baidu.com/it/u=2525444497,3926769801&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img1.baidu.com/it/u=1622699731,2387912926&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img1.baidu.com/it/u=2603763568,553613880&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img0.baidu.com/it/u=897610780,2733025773&fm=253&fmt=auto&app=138&f=PNG?w=120&h=120'
        },   {
          'img': 'https://img0.baidu.com/it/u=1671708499,2457454968&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img0.baidu.com/it/u=2966861195,1513133659&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img0.baidu.com/it/u=3619946645,769941788&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img1.baidu.com/it/u=2568346834,347660496&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img1.baidu.com/it/u=2725804151,3072200411&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img1.baidu.com/it/u=596175957,2171475362&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': '1111111'
        },
      ],
      logoList:[],
      swiperOptions1: {
        // 自动播放
        autoplay: {
          delay: 3000,
        },
        // 环路播放
        loop: true,
        // 切换效果
        effect: 'slide',
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6
        },
        // 前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.next1',
          prevEl: '.swiper-button-prev.prev1',
        }
      },
      swiperOptions2: {
        // 自动播放
        autoplay: {
          delay: 3000,
        },
        // 环路播放
        loop: true,
        // 切换效果
        effect: 'slide',
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6
        },
        // 前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.next1',
          prevEl: '.swiper-button-prev.prev1',
        }
      },
      swiper: {
        loop: true, // 循环模式选项

        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination.incubation-s',
          bulletClass : 'my-bullet-2',//需设置.my-bullet样式
          bulletActiveClass: 'my-bullet-active',
          clickable :true,
        },
        navigation: {
          nextEl: '.swiper-button-next.next-right1',
          prevEl: '.swiper-button-prev.prev-left1',
        },
        uniqueNavElements: false,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        initialSlide :0,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      },
      swiper2: {
        direction: 'vertical', // 垂直切换选项
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination.yanxuan_pagination',
          bulletClass : 'my-bullet-shop',//需设置.my-bullet样式
          bulletActiveClass: 'my-bullet-active',
          clickable :true,
        },
        navigation: {
          nextEl: '.swiper-button-next.swiper-button-bottom',
          prevEl: '.swiper-button-prev.swiper-button-top',
        },
        height: 460,//你的slide高度 强制性 垂直方向生效
        effect: 'coverflow', //进出动画
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        //longSwipesRatio: 0.2, //滑动多少就可以滑动
        coverflowEffect: {
          slideShadows: false, // 页面阴影效果
          rotate: 0,// 旋转的角度
          stretch: 510,// 拉伸 图片间左右的间距和密集度
          depth: 0,// 深度 切换图片间上下的间距和密集度
          modifier: .8,// 修正值 该值越大前面的效果越明显
        },
        slidesPerColumn: 1, //竖着展示2张图片
        slidesPerGroup: 1,
        on: {
          progress: function (progress) {
            for (let i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              var slideProgress = this.slides[i].progress;
              slide.css('opacity', 1);
              if (slideProgress == -1) {
                slide.css('opacity', 0.5);
              }
              if (slideProgress == -2) {
                slide.css('opacity', 0.3);
              }
              if (slideProgress <= -3 || slideProgress >= 1) {
                slide.css('opacity', 0);
              }
            }
          },
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        initialSlide :0,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      },
      swiper3:  {
        loop: true, // 循环模式选项
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // width: 1004,//你的slide高度 强制性 垂直方向生效
        // effect: 'coverflow', //进出动画
        // grabCursor: true,
        // centeredSlides: true,
        slidesPerView: 1,
        // coverflowEffect: {
        //   slideShadows: false, // 页面阴影效果
        //   rotate: 0,// 旋转的角度
        //   stretch: 1200,// 拉伸 图片间左右的间距和密集度
        //   depth: 0,// 深度 切换图片间上下的间距和密集度
        //   modifier: .8,// 修正值 该值越大前面的效果越明显
        // },
        slidesPerGroup: 1,
        // loopedSlides: 5,
        // spaceBetween: 0,
        on: {
          // progress: function (progress) {
          //   console.log(this.slides)
          //   for (let i = 0; i < this.slides.length; i++) {
          //     var slide = this.slides.eq(i);
          //     var slideProgress = this.slides[i].progress;
          //     slide.css('opacity', 1);
          //     if (slideProgress == -1) {
          //       slide.css('opacity', 0.5);
          //     }
          //     if (slideProgress == -2) {
          //       slide.css('opacity', 0.3);
          //     }
          //     if (slideProgress <= -3 || slideProgress >= 1) {
          //       slide.css('opacity', 0);
          //     }
          //   }
          // },
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        initialSlide: 0,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      },
      swiper_info: {
        loop: false, // 循环模式选项
        loopedSlides:2,
        loopAdditionalSlides: 3,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // width: 823,
        spaceBetween: -50, // 在slide之间设置距离（单位px）。
        effect: "coverflow",
        coverflowEffect: {
          rotate: -0, // 旋转的角度
          stretch: 0, // 拉伸 图片间左右的间距和密集度
          depth: 100, // 深度 切换图片间上下的间距和密集度
          modifier: 0.0001, // 修正值 该值越大前面的效果越明显
          slideShadows: false, // 页面阴影效果
        },
        breakpoints: {
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 2.01,
          },
          1000: {  //当屏幕宽度大于等于776
            slidesPerView: 2.01,
          },
          1200: {  //当屏幕宽度大于等于1200
            slidesPerView: 2.01,
          }
        },
        centeredSlides: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        initialSlide :0,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      },
      swiper_info2: {
        loop: true, // 循环模式选项
        loopedSlides:2,
        loopAdditionalSlides: 3,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // width: 823,
        // slidesPerView: 2.81,
        spaceBetween: -50, // 在slide之间设置距离（单位px）。
        effect: "coverflow",
        coverflowEffect: {
          rotate: -0, // 旋转的角度
          stretch: 0, // 拉伸 图片间左右的间距和密集度
          depth: 100, // 深度 切换图片间上下的间距和密集度
          modifier: 0.0001, // 修正值 该值越大前面的效果越明显
          slideShadows: false, // 页面阴影效果
        },
        breakpoints: {
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 2.81,
          },
          1000: {  //当屏幕宽度大于等于776
            slidesPerView: 2.81,
          },
          1200: {  //当屏幕宽度大于等于1200
            slidesPerView: 2.81,
          }
        },
        // watchSlidesProgress: true,
        on: {
          progress: function() {
            for (let i = 0; i < this.slides.length; i++) {
              const slide = this.slides.eq(i) // 指定匹配元素集缩减值
              const slideProgress = this.slides[i].progress // 当前元素集的progress值

              let modify = 0 // 偏移权重
              if (parseInt(Math.abs(slideProgress)) > 0) {
                modify = Math.abs(slideProgress) * 0.2 // 不一定要0.2，可自行调整
              }
              const translate = slideProgress * modify * 700 + 'px' // 500是swiper-slide的宽度
              const scale = 1 - Math.abs(slideProgress) / 5 // 缩放权重值，随着progress由中向两边依次递减，可自行调整
              const zIndex = 99 - Math.abs(Math.round(10 * slideProgress))
              slide.transform(`translateX(${translate}) scale(${scale})`)
              slide.css('zIndex', zIndex)
              slide.css('opacity', 1) // 是否可见
              if (parseInt(Math.abs(slideProgress)) > 1) { // 设置了只有选中的元素以及他两遍的显示，其他隐藏
                slide.css('opacity', 0)
              }
            }
          },
        },
        centeredSlides: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        initialSlide :1,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      },
      form: {
        brand: '', // 品牌/商品
        live_id: '', // 合作意向主播
        desc: '', // 合作需求简介
        is_brand: '', // 是否为品牌直营
        wechat: '', // 微信号
        tel: '', // 联系电话
      },

      self_incubation: [], // 宅机弟自孵化账号
      yanXuan_list: [], // 华星严选
      explosion_list: [], // 爆款案例
      information_list: [], // 近期资讯
      brand_list: [], // 合作品牌
      shop_join_img: '', // 商务合作背景图
      defaultOption:{
          step: 0.2, // 数值越大速度滚动越快
          limitMoveNum: 2,
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 2, // 0向下 1向上 2向左 3向右
          // openWatch: true, // 开启数据实时监控刷新dom
          // singleHeight: -0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  computed: {
    swiper4(){
      var len = this.information_list.length
      if(len == 1){
        return this.swiper_info
      }else{
        return this.swiper_info2
      }
    },
    rules:function(){
      let data ={
        brand: [
          { required: true, message: this.$t('LIVE.rules_brandText'), trigger: 'blur' }
        ],
          live_id: [
          { required: true, message: this.$t('LIVE.rules_intention'), trigger: 'blur' }
        ],
          desc: [
          { required: true, message: this.$t('LIVE.rules_cooperationNeeds'), trigger: 'blur' }
        ],
          is_brand: [
          { required: true, message: this.$t('LIVE.rules_brandDirectSale'), trigger: 'blur' }
        ],
          wechat: [
          { required: true, message: this.$t('LIVE.rules_wechat'), trigger: 'blur' }
        ],
          tel: [
          { required: true, message: this.$t('LIVE.rules_phone'), trigger: 'blur' }
        ]
      }
      return data
    },

  },
  mounted() {
    this.get_Logo()
    this.get_index_List() // 商务合作背景图
    this.get_shopBanner(1) // 1宅机弟自孵化账号
    this.get_shopBanner(2) // 2华星严选
    this.get_shopBanner(3) // 3爆款案例
    this.get_shopBanner(4) // 4近期资讯
    this.get_brand() // 合作品牌
    let  language=localStorage.getItem('locale')
  },
  methods: {
    gotoMore:function(){
      this.$router.push({
        path:'/aboutUs'
      })
    },
    get_Logo: function() {
      Logo(4).then(resp => {
        if (resp.status== 200) {
          this.$set(this, 'logoList', resp.data)
        }
      }).catch(error => {

      })
    },
    // 商务合作背景图
    get_index_List: function() {
      index_List().then(resp => {
        if (resp.status== 200) {
          this.$set(this, 'shop_join_img', resp.data.shop_join_img)
        }
      }).catch(error => {
      })
    },
    get_shopBanner: function(data) {
      getShopBanner({ type: data }).then(resp => {
        if(resp.status== 200) {
          if(data == 1) {
            this.$set(this, 'self_incubation', resp.data)
            this.$nextTick(()=>{
              let mySwiper = new Swiper ('.swiper1', this.swiper)
            })
          }
          if(data == 2) {
            this.$set(this, 'yanXuan_list', resp.data)
            this.$nextTick(()=> {
              let mySwiper2 = new Swiper('.swiper2', this.swiper2)
            })
          }
          if(data == 3) {
            this.$set(this, 'explosion_list', resp.data)
            this.$nextTick(()=> {
              let mySwiper3 = new Swiper('.swiper3', this.swiper3)
            })
          }
          if(data == 4) {
            this.$set(this, 'information_list', resp.data)
            this.$nextTick(()=>{
              let mySwiper4 = new Swiper ('.swiper4', this.swiper4)
            })
          }
        } else {
          this.$message(resp.data.msg)
        }
      }).catch(err => {
      })
    },
    // 合作品牌
    get_brand: function() {
      getBrand().then(resp => {
        if(resp.status== 200) {
          this.$set(this, 'brand_list', resp.data)
        } else {
          this.$message(resp.msg)
        }
      }).catch(err => {
      })
    },
    onSubmit: function(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          shopJoin(this.form).then(resp => {
            if(resp.status== 200) {
              this.$message({
                message: '提交成功',
                type: 'success'
              });
              this.form = {
                brand: '', // 品牌/商品名称
                live_id: '', // 合作意向主播
                desc: '', // 合作需求简介
                is_brand: '', // 是否为品牌直营
                wechat: '', // 微信号
                tel: '', // 联系电话
              }
            } else {
              this.$message(resp.data.msg)
            }
          }).catch(err => {
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style scoped lang='scss'>
.head-info-item{
  //display: flex;
  position: relative;
  padding: 30px 0;
  .header-anchor{
    width: 60%;
    margin: auto;
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333;
    //position: relative;
    line-height: 30px;
    .images{
      width: 321px;
      position: absolute;
      left: 50%;
    }
    .popular-img{
      width: 191px;
    }
    .explosion-img{
      width: 221px;
    }
    .information-img{
      width: 261px;
    }
    .address-word{
      width: 181px;
    }
    .shop-content-info{
      //background: rgba(213, 71, 36, 0.1);
      //box-shadow: 0px 8px 6px 0px rgba(153, 153, 153, 0.1);
      border-radius: 40px;
      height: 428px;
      width: 88%;
      overflow: hidden;
      margin: auto;
    }
    .hx-yanxuan{
      height: 576px;
      width: 100%;
      overflow: hidden;
      margin: auto;
      //background: #F2F2F2;
      border-radius: 40px 40px 40px 40px;
      //box-shadow: 0px 0px 7px 0px rgba(60,18,7,0.1500);
      .yanxuan_pagination{
        right: -30px;
      }
    }
  }
  .rectangle-position{
    position: absolute;
    bottom: 30px;
    left: 60px;
    img{
      width: 104px;
      height: 73px;
    }
  }
  .lefts{
    left: 140px;
    bottom: 240px;
  }
  .rectangle-right{
    position: absolute;
    top: 80px;
    right: 100px;
    img{
      width: 106px;
      height: 73px;
    }
  }
  .left-s{
    position: absolute;
    left: 0;
    top: -200px;
    opacity: 0.3;
    img{
      width: 355px;
      height: 657px;
    }
  }
  .rights{
    right: 160px;
  }
  .swiper-slide{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #666666;
    position: relative;
  }
  .anchor-list{
    padding: 30px 0 0 70px;
  }
  .anchor-list div:nth-child(1){
    font-size: 20px;
    color: #333333;
    line-height: 50px;
  }
  .anchor-list div:nth-child(3){
    margin: 50px 0 150px 0;
  }
  .anchor-list span:nth-child(4){
    background: #D54724;
    border-radius: 23px;
    padding: 8px 20px;
    color: #fff;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
  }
  .anchor-avatar{
    width: 100%;
    height: 100%;
    border-radius: 40px;
  }
  .yanxuan-list{
    height: auto;
    width: 98%;
  }
  .lists-con{
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #FFFFFF;
  }
  .yanxuan-avatar{
    width: 100%;
    height: 446px;
  }
  .yanxuan-name{
    font-size: 18px;
    line-height: 47px;
    padding-left: 76px;
  }
  .yx-number{
    padding-left: 76px;
    line-height: 25px;
  }
  .yx-number > div:nth-child(2) {
    margin-bottom: 10px;
  }
  .yx-number > span:nth-child(3) {
    background: #FFFFFF;
    color: #D54724;
    padding: 5px 5px;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: bold;
  }
  .hot-case{
    width: 87%;
    margin: auto;
    height: 567px;
    overflow: hidden;
    .swiper-button-prev{
      left: 20px;
    }
    .swiper-button-next{
      right: 20px;
    }
    .swiper-slide{
      //width: 1004px !important;
    }
    .avatar-explosion{
      width: 1004px;
      height: 100%;
      border-radius: 40px;
    }
  }
  .avatar2{
    width: 100%;
    height: 100%;
    //box-shadow: 0px 3px 3px 0px rgba(64, 64, 64, 0.1);
    border-radius: 40px;
  }
  .hot-information{
    width: 100%;
    margin: auto;
    height: 234px;
    overflow: hidden;
    .swiper-button-left{
      left: -8.5%;
    }
    .swiper-button-right{
      right: -8%
    }
    .swiper-slide-duplicate-next{
      opacity: 0 !important;
    }
    .swiper-slide-duplicate-prev{
      opacity: 0 !important;
    }
    .swiper-wrapper{
     align-items: center;
    }
    .swiper-slide-prev,.swiper-slide-next{
      width: 312px !important;
      height: 215px !important;
    }
    .swiper-slide-active {
      width: 512px !important;
      height: 234px !important;
      z-index: 33 !important;
    }
  }
  /*.cooperation-brand{*/
  /*  display: flex;*/
  /*  justify-content: space-between;*/
  /*  flex-wrap: wrap;*/
  /*  img{*/
  /*    width: 102px;*/
  /*    height: 102px;*/
  /*    box-shadow: 6px 6px 5px 0 rgba(243, 228, 225, 1);*/
  /*    border-radius: 20px;*/
  /*    margin-top: 20px;*/
  /*    margin-right: 30px;*/
  /*  }*/
  /*}*/
}
.brand_wrapper{
  width: 100%;
  margin-top: 120px;
  .brand_title{
    text-align: center;
    font-size: 26px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
    margin-bottom: 50px;
  }
  .brand_content{
    width: 100%;
    background-color: #f6f6f6;
    height: 324px;
    .seamless-warp2{
      width: 100%!important;
      overflow: hidden;
      .item{
        width: 100%!important;
        li{
          width: 120px;
          height: 120px;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          margin-right: 35px;
          margin-bottom: 20px;
          /*.wrapper_img{*/
          /* width: 120px;*/
          /*  height: 120px;*/
            img{
              width: 120px;
              height: 120px;
            }
          /*}*/
        }
      }
    }

  }
}
.banner_1 {
  width: 100%;
  height: 700px;

  .swiper_content {
    width: 100%;
    height: 700px;

    .swiper-container {
      width: 100%;
      height: 700px;

      .swiper-wrapper {
        width: 100%;
        height: 700px;

        .swiper-slide {
          width: 100%;
          height: 700px;

          img {
            width: 100%;
            height: 700px;
          }
        }
      }
    }
    img {
      width: 100%;
      height: 700px;
    }
  }
}
.recruit-item{
  height: 700px;
  width: 100%;
  position: relative;
  img{
    height: 700px;
    width: 100%;
  }
  .content-s{
    //width: 55%;
    margin: auto;
    //padding-top: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top:120px;
    z-index: 2;
    left: 22%;
  }
  .form-item-s{
    width: 283px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(170, 169, 169, 0.44);
    border-radius: 40px;
    height: 450px;
    padding: 15px;
    .back-s{
      //background: #F19F8A;
      //border-radius: 25px;
      //padding: 0 10px 0 10px;
    }
  }
  .form-item-s .name-d {
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 50px;
    margin-bottom: 10px;
  }
  .data-see{
    padding: 10px;
    margin-left: 105px;
    .image-platform{
      width: 505px;
      height: 303px;
    }
  }
}
.swiper1 {
  width: 100%;
  height: 428px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px;/* 设置按钮大小 */
}
.swiper2 {
  width: 95%;
  height: 576px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px;/* 设置按钮大小 */
}
.swiper3 {
  width: 97%;
  height: 567px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px;/* 设置按钮大小 */
  margin:auto;
  overflow: hidden;
}
.swiper4 {
  width: 85%;
  height: 234px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px;/* 设置按钮大小 */
  margin:auto;
}
.map-container {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  background-color: rgba(213, 71, 36, 0.1);
}
.know_us {
  text-align: left;
  position:absolute;
  z-index: 999;
  bottom: 35px;
  left: 80px;
  .know_more {
    background-color: #D54724;
    color: #fff;
    cursor: pointer;
  }
  .know_more:hover{
    background-color: rgba(213,71,26,0.5);

  }
  .know_more_nodata{
    background-color: rgba(213,71, 36, 0.5);
    color: #fff;
    //cursor:not-allowed;
  }
}

.shop-content-info .el-button {
  border: none;
  outline: none;
  border-radius: 50px;
}
.prev-left1{
  left:20%;
  top: 60%;
}
.next-right1{
  right: 20%;
  top: 60%;
}

.prev-left2{
  left:20%;
}
.next-right2{
  right: 20%;
}

</style>
